import React from 'react';
import Chatbot from './components/Chatbot';
import './App.css';

function App() {
  return (
    <div className="container">
      <header className="header">
        <div className="title-container">
          <h1 className="main-title">HANK</h1>
          <h2 className="subtitle">AACE Recommended Practice Expert</h2>
        </div>
      </header>
      <div className="chatbot-wrapper">
        <Chatbot />
      </div>
      <footer className="footer">
        <div className="logotext-container">
          <div className="logo-text">in association with</div>
          <img src="static/AACE-Banner.png" alt="AACE Logo" className="logo logo-left" />
        </div>          
        <div className="logotext-container">
          <div className="logo-text">powered by</div>
          <img src="static/asset_1b.png" alt="PB Logo" className="logo logo-right" />
        </div>
      </footer>
    </div>
  );
}
export default App;
