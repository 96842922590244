// config.js

const config = {
    // API_BASE_URL: 'https://fish-enhanced-presently.ngrok-free.app/api'
    // test change for CICD deployment pipeline
  API_BASE_URL: process.env.NODE_ENV === 'production'
    ? '/api'
    : (process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api')
};

export default config;
