import React, { useState, useEffect} from 'react';
import axios from 'axios';
import config from '../config';
import './Chatbot.css';
import showdown from 'showdown';
import sanitizeHtml from 'sanitize-html';

function Chatbot() {
  const [messages, setMessages] = useState([
    { text: "Hi, I'm Hank! Ask me anything to do with AACE recommended practices", type: 'botText' }
  ]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [converter] = useState(new showdown.Converter({
    tables: true,
    strikethrough: true,
    tasklists: true,
    smoothLivePreview: true,
    smartIndentationFix: true,
    simpleLineBreaks: true,
    openLinksInNewWindow: true,
    emoji: true,
    parseImgDimensions: true,
    simplifiedAutoLink: true,
    literalMidWordUnderscores: true,
    ghCodeBlocks: true,
    ghCompatibleHeaderId: true,
    disableForced4SpacesIndentedSublists: true
  }));

  useEffect(() => {
    // Clear the existing session ID
    sessionStorage.removeItem('session_id');
    
    // Generate a new session ID
    const newSessionId = `session_${Math.random().toString(36).substring(2, 15)}`;
    sessionStorage.setItem('session_id', newSessionId);
    setSessionId(newSessionId);
  }, []);

  const handleInput = (e) => setInput(e.target.value);

  const getBotResponse = async (userInput) => {
    if (userInput.trim() === '') return;

    const userMessage = { text: userInput, type: 'user' };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setIsLoading(true);

    try {
      const response = await axios.get(`${config.API_BASE_URL}/get`, {
        params: { msg: userInput, session_id: sessionId },
        timeout: 720000,
        headers: {
          'Ngrok-Skip-Browser-Warning': 'true'
        }
      });
      const botMessage = { 
        text: response.data.text,
        sources: response.data.sources,
        type: 'bot' 
      };
      setMessages(prevMessages => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("There was an error getting the response!", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      const userInput = input;
      setInput('');  // Clear the input immediately
      getBotResponse(userInput);  // Pass the user input to getBotResponse
    }
  };
  

  const sanitizeMarkdown = (markdownText) => {
    // Convert markdown to HTML
    const htmlContent = converter.makeHtml(markdownText);
    console.log(htmlContent)
    
    // Sanitize the HTML
    let sanitizedHtml = sanitizeHtml(htmlContent, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'table', 'thead', 'tbody', 'tr', 'th', 'td']),
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        'img': ['src', 'alt'],
        'table': ['class'],
        'th': ['scope'],
        'td': ['align']
      }
    });
  
    // Remove extra spaces between list items
    sanitizedHtml = sanitizedHtml.replace(/<\/li>\s+<li>/g, '</li><li>');
  
    // Remove spaces before and after lists
    sanitizedHtml = sanitizedHtml.replace(/>\s*(<[ou]l>)\s*/g, '>$1');
    sanitizedHtml = sanitizedHtml.replace(/\s*(<\/[ou]l>)\s*</g, '$1<');
    sanitizedHtml = sanitizedHtml.replace(/>\s*(<li>)\s*/g, '>$1');
    sanitizedHtml = sanitizedHtml.replace(/\s*(<\/li>)\s*</g, '$1<');
  
    // Remove empty paragraphs
    sanitizedHtml = sanitizedHtml.replace(/<p>\s*<\/p>/g, '');
  
    // Remove space after h3 headings
    sanitizedHtml = sanitizedHtml.replace(/<\/h3>\s+/g, '</h3>');
    // Remove space before h3 headings
    sanitizedHtml = sanitizedHtml.replace(/\s+<h3>/g, '<h3>');

    return sanitizedHtml;
  };

  const renderMessage = (msg) => {
    if (msg.type === 'user') {
      return <pre>{msg.text}</pre>;
    } else {
      const sanitizedHtmlContent = sanitizeMarkdown(msg.text);
      return <div dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }} />;
    }
  };

  return (
    <div id="chatbox">
      <div className="messages-container">
        {messages.map((msg, index) => (
          <div key={index} className="message-wrapper">
            <div className={msg.type === 'user' ? 'userText' : 'botText'}>
             {renderMessage(msg)}            
            
              {/* <pre>{msg.text}</pre> */}

            </div>
            {msg.sources && Array.isArray(msg.sources) && msg.sources.map((source, sourceIndex) => (
              <div key={sourceIndex} className="sourceText">{source}</div>
            ))}
          </div>
        ))}
        {isLoading && (
          <div className="loading-indicator">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        )}
      </div>
      <div id="userInput">
        <input
          id="textInput"
          type="text"
          value={input}
          onChange={handleInput}
          onKeyPress={handleKeyPress}
          placeholder="Type your message here..."
        />
      </div>
    </div>
  );
}

export default Chatbot;

